import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { useTheme } from 'styled-components'

import { MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP_NUM } from '@/features/navigation/constants'

/**
 * This hook is used to manage the collapsed view of the mega menu
 * @param debounceTime - The debounce time for the resize event
 * @returns isCollapsedMenuView - A boolean indicating if the mega menu is in collapsed view
 */
export const useCollapsedView = (debounceTime?: number) => {
  const [isCollapsedMenuView, setIsCollapsedMenuView] = useState<boolean>(false)
  const { breakpoints } = useTheme()

  /** The modal should not appear on desktop view */
  useEffect(() => {
    const handleResize = debounce(() => {
      if (window.innerWidth < MEDIA_QUERY_NAVIGATION_MEDIUM_DESKTOP_NUM) {
        setIsCollapsedMenuView(true)
      } else {
        setIsCollapsedMenuView(false)
      }
    }, debounceTime || 100)

    window.addEventListener('resize', handleResize)

    // Initial check on component mount
    handleResize()

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [breakpoints.large, debounceTime])

  return { isCollapsedMenuView }
}
