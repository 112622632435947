import { useTranslation } from 'next-i18next'
import {
  type ForwardedRef,
  type KeyboardEvent,
  type MouseEvent,
  type PropsWithChildren,
  forwardRef,
  useRef,
} from 'react'

import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { attachRadialLocationParams } from '@/domain/location/attachRadialLocationParams'
import { TrendingCategory } from '@/features/navigation/components/mega-menu/common/TrendingCategory'
import { MoreMenu } from '@/features/navigation/components/mega-menu/desktop/MoreMenu'
import {
  L1MenuContainer,
  L1MenuIconsListContainer,
  L1MenuLink,
  L1MenuList,
  L1MenuListItem,
  L1MenuNav,
  megaMenuCustomSlideStyle,
  MegaMenuMobileL1IconContainer,
} from '@/features/navigation/components/styled'
import { type ActiveMegaMenuReturn } from '@/features/navigation/hooks/useActiveMegaMenu'
import { getCategoryIcon } from '@/features/navigation/utils/getCategoryIcon'
import { useGetLocation } from '@/hooks/location/useGetLocation'

export type MegaMenuInlineCategoriesProps = Pick<
  ActiveMegaMenuReturn,
  | 'closeMenu'
  | 'handleClearTimeout'
  | 'isCollapsedMenuView'
  | 'l1MenuItems'
  | 'menuBarRef'
  | 'onHover'
  | 'openMenu'
  | 'selectedL1Item'
  | 'icons'
> &
  PropsWithChildren

export const L1Menu = forwardRef(function L1Menu(
  {
    children,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    l1MenuItems,
    menuBarRef,
    onHover,
    openMenu,
    selectedL1Item,
    icons = true,
  }: MegaMenuInlineCategoriesProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const { location } = useGetLocation()
  const { t } = useTranslation('global_header')
  const l1MenuContainerRef = useRef<HTMLDivElement>(null)

  const handleL1Click = (e: MouseEvent<HTMLAnchorElement>, categoryId: number) => {
    /**
     * Prevent L1 link from redirecting user to SRP on click
     * While still keeping it a link for SEO purposes
     * */
    e.preventDefault()

    openMenu(categoryId, 1, true)
  }

  if (!l1MenuItems?.length) return null

  const handleCloseMenu = () => closeMenu(1)

  /** Get 2 last items of the array to add to "more" ellipsis menu */
  const moreMenuItems = l1MenuItems.slice(-2)

  const onKeyDown = (event: KeyboardEvent<HTMLLIElement>, categoryId: number) => {
    switch (event.key) {
      case 'Enter':
      case ' ': {
        event.preventDefault()
        openMenu(categoryId, 1, true, true)
        break
      }
    }
  }

  const onChildKeyDown = (event: KeyboardEvent<HTMLLIElement>) => {
    switch (event.key) {
      case 'Escape': {
        const nextElementSibling = l1MenuContainerRef.current?.nextElementSibling
        // set focus to the next element in the DOM (i.e. Trending Category)
        if (nextElementSibling instanceof HTMLElement) nextElementSibling?.focus()
        break
      }
    }
  }

  const listItems = l1MenuItems.map((item) => {
    if (!item) return null

    const icon = getCategoryIcon(item.id)
    const handleOpenMenu = () => openMenu(item.id, 1)
    const handleHover = () => onHover(item.id, 1)
    const seoUrl = attachRadialLocationParams(item.seoUrl, location)

    return (
      <L1MenuListItem
        isActive={item.id === selectedL1Item?.id}
        key={`${item.categoryName}-${item.id}`}
        onBlur={handleClearTimeout}
        onMouseEnter={handleOpenMenu}
        onMouseMove={handleHover}
        onMouseOut={handleClearTimeout}
        onKeyDown={(e) => onKeyDown(e, item.id)}
        role="menuitem"
      >
        <L1MenuLink
          aria-controls={`cat-menu-group-${item.id}`}
          aria-expanded={selectedL1Item?.id === item.id}
          aria-haspopup="true"
          href={seoUrl}
          onClick={(e) => handleL1Click(e, item.id)}
          ref={selectedL1Item?.id === item.id ? ref : null}
        >
          {icons && <MegaMenuMobileL1IconContainer>{icon}</MegaMenuMobileL1IconContainer>}
          <span>{item.categoryName}</span>
        </L1MenuLink>
      </L1MenuListItem>
    )
  })

  const handleMouseLeave = () => {
    if (isCollapsedMenuView) return

    closeMenu(2)
  }

  return (
    <L1MenuNav aria-label={t('mega_menu.nav.label')} onMouseLeave={handleMouseLeave}>
      <L1MenuContainer onMouseLeave={handleCloseMenu} tabIndex={0} ref={l1MenuContainerRef}>
        <L1MenuIconsListContainer>
          <LegacyCarousel
            listCustomProps={{
              'aria-label': t('mega_menu.nav.label'),
              role: 'menubar',
              ref: menuBarRef,
            }}
            dragFree
            name="hp-similar-to-favourites-carousel"
            preventNestedListItem
            removeHorizontalSpacing
            shouldShowArrows={[true, false, false]}
            slideCustomStyle={megaMenuCustomSlideStyle}
            slides={listItems}
            slidesToShow={[8, 8, 3.5]}
            useLegacyLgDesktopBreakpoint
          />
        </L1MenuIconsListContainer>

        <L1MenuList role="menubar">
          {listItems}
          <MoreMenu moreMenuItems={moreMenuItems} parentOnKeyDownHandler={onChildKeyDown} />
        </L1MenuList>

        {/* Submenu L2/L3 */}
        {children}
      </L1MenuContainer>

      <TrendingCategory />
    </L1MenuNav>
  )
})
