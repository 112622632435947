import {
  type GetMenuPrefetchQuery,
  type MenuItem,
  useGetMenuPrefetchQuery,
} from '@kijiji/generated/graphql-types'
import { type MutableRefObject, useEffect, useMemo, useRef, useState } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { HOVER_DELAY_MS } from '@/features/navigation/constants'
import { useCollapsedView } from '@/features/navigation/hooks/useCollapsedView'
import { isMenuItem, isMenuitemArray } from '@/features/navigation/types/navigation'
import { useBrandedCategoryCampaign } from '@/hooks/campaigns/useBrandedCategoryCampaign'
import { useLocale } from '@/hooks/useLocale'
import { type BrandedCategoryData } from '@/types/customCampaigns'
import { isMobileDevice } from '@/utils/userAgent'

type MenuPrefetch = GetMenuPrefetchQuery['menuPrefetch']

export type ActiveMegaMenuReturn = {
  activeL2Items: MenuItem[]
  activeL3Items: MenuItem[]
  brandedCategoryCampaign?: BrandedCategoryData
  closeMenu: (level: number) => void
  handleClearTimeout: () => void
  isCollapsedMenuView: boolean
  isKeyboardNavigation: MutableRefObject<boolean>
  l1MenuItems: MenuPrefetch
  menuBarRef: MutableRefObject<null>
  onHover: (categoryId: number, level: number) => void
  openMenu: (categoryId: number, level: number, isClick?: boolean, isKeyboard?: boolean) => void
  selectedL1Item?: MenuItem
  selectedL2Item?: MenuItem
  icons?: boolean
}

/**
 * This hook is used to manage the state of the mega menu
 * and the logic to open and close the menu
 */
export const useActiveMegaMenu = (locationId: number): ActiveMegaMenuReturn => {
  const { cookieLocale } = useLocale()

  const [selectedL1Id, setSelectedL1Id] = useState<number>(0)
  const [selectedL2Id, setSelectedL2Id] = useState<number>(0)

  const menuBarRef = useRef(null)
  const menuTimeout = useRef<NodeJS.Timeout | null>(null)
  const isKeyboardNavigation = useRef(false)
  const { isCollapsedMenuView } = useCollapsedView()

  const { campaign } = useBrandedCategoryCampaign(locationId)

  const { data: menu, previousData } = useGetMenuPrefetchQuery({
    fetchPolicy: 'cache-first',
    variables: {
      categoryId: ALL_CATEGORIES_ID_NUM,
      locationId,
      depth: 1,
      hints: [],
      locale: cookieLocale,
    },
  })
  /** List of L1 Menu Items */
  const l1MenuItems = menu?.menuPrefetch || previousData?.menuPrefetch || []

  /** Dynamic values based on which items have been selected  */
  const selectedL1Item = useMemo(
    () => l1MenuItems.find((item) => item?.id === selectedL1Id),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedL1Id]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const activeL2Items = useMemo(() => selectedL1Item?.children || [], [selectedL1Item?.id])
  const selectedL2Item = useMemo(
    () => activeL2Items.find((item) => item?.id === selectedL2Id),
    [activeL2Items, selectedL2Id]
  )

  const { data: l3Data } = useGetMenuPrefetchQuery({
    ssr: false,
    fetchPolicy: 'cache-first',
    skip: !selectedL2Id || selectedL1Item?.isLeaf,
    variables: {
      categoryId: selectedL2Id,
      locationId,
      depth: 1,
      hints: [],
      locale: cookieLocale,
    },
  })

  const activeL3Items = selectedL2Id ? l3Data?.menuPrefetch || [] : []

  useEffect(() => {
    return handleClearTimeout()
  })

  const selectL1Category = (categoryId: number, isClick?: boolean) => {
    if (selectedL1Id === categoryId) return

    /** If was triggered by a click, it doesn't need to set a timeout */
    if (isClick) {
      setSelectedL1Id(categoryId)
    } else {
      menuTimeout.current = setTimeout(() => {
        setSelectedL1Id(categoryId)
      }, HOVER_DELAY_MS)
    }
  }

  const selectL2Category = (categoryId: number, isClick?: boolean) => {
    if (selectedL2Id === categoryId) return

    /** If was triggered by a click, it doesn't need to set a timeout */
    if (isClick) {
      setSelectedL2Id(categoryId)
    } else {
      menuTimeout.current = setTimeout(() => {
        setSelectedL2Id(categoryId)
      }, HOVER_DELAY_MS)
    }
  }

  /** Used to set an L1 category and open the L2 menu */
  const openMenu = (
    categoryId: number,
    level?: number,
    isClick?: boolean,
    isKeyboard?: boolean
  ) => {
    handleClearTimeout()

    /**
     * Should only open the menu if is not mobile view or
     * if it was triggered by a click
     */
    if (!isClick && isCollapsedMenuView) return

    /**
     * check whether the call was triggered by keyboard navigation
     * we use this to focus the "See All..." link when the submenu is opened
     */
    if (isKeyboard) {
      isKeyboardNavigation.current = true
    } else {
      isKeyboardNavigation.current = false
    }

    if (level == 1) {
      selectL1Category(categoryId, isClick)
      return
    }

    if (level == 2) {
      selectL2Category(categoryId, isClick)
    }
  }

  const onHover = (categoryId: number, level: number) => {
    // Do nothing for "hover/focus" on mobile/tablet views (e.g., iOS simulates hover with taps)

    if (isMobileDevice(navigator.userAgent)) {
      handleClearTimeout()
      return
    }

    openMenu(categoryId, level)
  }

  const closeMenu = (level: number) => {
    handleClearTimeout()

    if (level === 2) {
      setSelectedL1Id(0)
      setSelectedL2Id(0)
      return
    }

    if (level === 3) {
      setSelectedL2Id(0)
      return
    }
  }

  const handleClearTimeout = () => {
    menuTimeout.current && clearTimeout(menuTimeout.current)
  }

  /** KEYBOARD EVENTS */
  // const MENU_PREFIX = (id: string | number) => `cat-menu-${id}`
  // const MENU_WRAPPER_PREFIX = (id: string | number) => `cat-menu-wrapper-${id}`
  // const MENU_ITEM_PREFIX = (id: string | number) => `cat-menu-item-${id}`
  // const MENU_GROUP_PREFIX = (id: string | number) => `cat-menu-group-${id}`

  // const getActiveItem = (id: number, isTopLevel = false) => {
  //   if (isTopLevel) {
  //     return document.getElementById(MENU_ITEM_PREFIX(id))
  //   }

  //   return document.querySelector(`#${MENU_WRAPPER_PREFIX(activeMenuId)} #${MENU_ITEM_PREFIX(id)}`)
  // }
  // const getActiveElement = () => global.document && global.document.activeElement

  // const onKeyDownHandler = (event: KeyboardEvent) => {
  //   if (!activeMenuId) {
  //     return
  //   }
  // }

  return {
    activeL2Items: isMenuitemArray(activeL2Items) ? activeL2Items : [],
    activeL3Items: isMenuitemArray(activeL3Items) ? activeL3Items : [],
    brandedCategoryCampaign: campaign,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    isKeyboardNavigation,
    l1MenuItems,
    menuBarRef,
    onHover,
    openMenu,
    selectedL1Item: isMenuItem(selectedL1Item) ? selectedL1Item : undefined,
    selectedL2Item: isMenuItem(selectedL2Item) ? selectedL2Item : undefined,
  }
}
