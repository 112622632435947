import { CATEGORIES } from '@kijiji/category'
import { type StaticImageData } from 'next/image'

import buySellSrc from '~/public/next-assets/images/homepage/navigation/L1_buy_and_sell.jpg'
import carsVehiclesSrc from '~/public/next-assets/images/homepage/navigation/L1_cars_and_vehicles.jpg'
import communitySrc from '~/public/next-assets/images/homepage/navigation/L1_community.jpg'
import jobsSrc from '~/public/next-assets/images/homepage/navigation/L1_jobs.jpg'
import petsSrc from '~/public/next-assets/images/homepage/navigation/L1_pets.jpg'
import realEstateSrc from '~/public/next-assets/images/homepage/navigation/L1_real_estate.jpg'
import serviceSrc from '~/public/next-assets/images/homepage/navigation/L1_services.jpg'
import vacationSrc from '~/public/next-assets/images/homepage/navigation/L1_vacation_rentals.jpg'

const CATEGORY_TO_SRC: { [categoryId: number]: StaticImageData } = {
  [CATEGORIES.BUY_AND_SELL_CATEGORY_ID]: buySellSrc,
  [CATEGORIES.CARS_AND_VEHICLES_CATEGORY_ID]: carsVehiclesSrc,
  [CATEGORIES.COMMUNITY_CATEGORY_ID]: communitySrc,
  [CATEGORIES.JOBS_CATEGORY_ID]: jobsSrc,
  [CATEGORIES.PETS_CATEGORY_ID]: petsSrc,
  [CATEGORIES.REAL_ESTATE_CATEGORY_ID]: realEstateSrc,
  [CATEGORIES.SERVICES_CATEGORY_ID]: serviceSrc,
  [CATEGORIES.VACATION_RENTALS_CATEGORY_ID]: vacationSrc,
}

export const getCategoryImage = (categoryId: number): StaticImageData => {
  return CATEGORY_TO_SRC[categoryId]
}
