export enum TRANSLATION_KEYS {
  ACCOUNT = 'account',
  AUTOCANADA = 'autocanada',
  COMMON = 'common',
  FAVOURITES = 'favourites',
  FEEDBACK = 'feedback',
  FORGOT_PASSWORD = 'forgot_password',
  GLOBAL_FOOTER = 'global_footer',
  GLOBAL_HEADER = 'global_header',
  HOME = 'home',
  LISTING = 'listing',
  LOGIN = 'login',
  MOBILE_LINK = 'mobile_link',
  NOT_FOUND = '404',
  PAID_FEATURES = 'paidFeatures',
  PROFILE = 'profile',
  R2S = 'r2s',
  REGISTER = 'register',
  RESET_PASSWORD = 'reset_password',
  ROUTES = 'routes',
  SRP = 'srp',
  VIP = 'vip',
}
