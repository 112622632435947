import { SortDirection, SrpSortBy } from '@kijiji/generated/graphql-types'

export const FILTER_CANONICAL = {
  ADTYPE_SECTION: 'adTypeSection',
  BIRTH_DATE: 'birthdate',
  CARYEAR_RANGE: 'caryear',
  CATEGORY: 'category',
  CATEGORY_SECTION: 'categorySection',
  LOCATION: 'location',
  LOCATION_SECTION: 'locationSelection',
  MILEAGE_RANGE: 'carmileageinkms',
  PRICE_RANGE: 'price',
  PRICE_SECTION: 'priceSection',
  PRICE_TYPE: 'priceType',
} as const

export const LOW_INVENTORY_LIMIT = 20

export const SORT_BY_KEYS = {
  DATE_ASC: 'dateAsc',
  DATE_DESC: 'dateDesc',
  PRICE_ASC: 'priceAsc',
  PRICE_DESC: 'priceDesc',
  DISTANCE_ASC: 'distanceAsc',
  DISTANCE_DESC: 'distanceDesc',
  MATCH_DESC: 'matchDesc',
  START_DATE_ASC: 'start_dateAsc',
  MILEAGE_ASC: 'mileageAsc',
  MILEAGE_DESC: 'mileageDesc',
} as const

export type SortByKeys = (typeof SORT_BY_KEYS)[keyof typeof SORT_BY_KEYS]

export const SORT_BY_OPTIONS: {
  [key in SortByKeys]: { sort: SrpSortBy; order: SortDirection }
} = {
  //Oldest
  [SORT_BY_KEYS.DATE_ASC]: {
    sort: SrpSortBy.Date,
    order: SortDirection.Asc,
  },
  //Newest
  [SORT_BY_KEYS.DATE_DESC]: {
    sort: SrpSortBy.Date,
    order: SortDirection.Desc,
  },
  //Lowest
  [SORT_BY_KEYS.PRICE_ASC]: {
    sort: SrpSortBy.Price,
    order: SortDirection.Asc,
  },
  //Highest
  [SORT_BY_KEYS.PRICE_DESC]: {
    sort: SrpSortBy.Price,
    order: SortDirection.Desc,
  },
  //Closest
  [SORT_BY_KEYS.DISTANCE_ASC]: {
    sort: SrpSortBy.Distance,
    order: SortDirection.Asc,
  },
  //Farthest
  [SORT_BY_KEYS.DISTANCE_DESC]: {
    sort: SrpSortBy.Distance,
    order: SortDirection.Desc,
  },
  //Best match
  [SORT_BY_KEYS.MATCH_DESC]: {
    sort: SrpSortBy.Match,
    order: SortDirection.Desc,
  },
  //Start date asc (used by garage sales)
  [SORT_BY_KEYS.START_DATE_ASC]: {
    sort: SrpSortBy.StartDate,
    order: SortDirection.Asc,
  },
  [SORT_BY_KEYS.MILEAGE_ASC]: {
    sort: SrpSortBy.Mileage,
    order: SortDirection.Asc,
  },
  [SORT_BY_KEYS.MILEAGE_DESC]: {
    sort: SrpSortBy.Mileage,
    order: SortDirection.Desc,
  },
}

export const MIN_AD_ID_CANDIDATE = 1000000

export const PROMOTIONAL_LISTING_AD_SOURCE = {
  TOP_AD: 'TOP_AD',
  PROV_TOP_AD: 'PROV_TOP_AD',
} as const
