import dynamic from 'next/dynamic'
import { type ForwardedRef, type KeyboardEvent, forwardRef } from 'react'

import { SubCategories } from '@/features/navigation/components/mega-menu/desktop/SubCategories'
import { MegaMenuModal } from '@/features/navigation/components/mega-menu/mobile/MegaMenuModal'
import {
  CategoryTreeWrapper,
  MenuImageInsetShadow,
  SubCategoryHiddenTitle,
  SubCategoryL2Group,
  SubCategoryL3Container,
  SubCategoryMenu,
} from '@/features/navigation/components/styled'
import { type ActiveMegaMenuReturn } from '@/features/navigation/hooks/useActiveMegaMenu'
import { getCategoryImage } from '@/features/navigation/utils/getCategoryImage'

const Image = dynamic(() => import('next/image').then((mod) => mod.default), { ssr: false })

const SUB_ITEMS_PER_COLUMN = 14

export type CategoryTreeProps = Pick<
  ActiveMegaMenuReturn,
  | 'activeL2Items'
  | 'activeL3Items'
  | 'brandedCategoryCampaign'
  | 'closeMenu'
  | 'handleClearTimeout'
  | 'isCollapsedMenuView'
  | 'isKeyboardNavigation'
  | 'openMenu'
  | 'selectedL1Item'
  | 'selectedL2Item'
>

export const CategoryTree = forwardRef(function CategoryTree(
  props: CategoryTreeProps,
  ref: ForwardedRef<HTMLAnchorElement>
) {
  const {
    activeL2Items,
    activeL3Items,
    brandedCategoryCampaign,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    isKeyboardNavigation,
    openMenu,
    selectedL1Item,
    selectedL2Item,
  } = props

  if (!selectedL1Item?.id || !selectedL1Item || !activeL2Items.length) return

  /**
   * There is a white shadow behind the list items that should show up  only when there are more than 1 column in the L3
   * This will prevent the text to overlap with the image without a gradient
   * */
  const numberOfColumns = activeL3Items?.length
    ? Math.ceil(
        (activeL3Items.length + (brandedCategoryCampaign ? 1 : 0) + 1) / SUB_ITEMS_PER_COLUMN
      )
    : 0
  const twoOrMoreColumns = numberOfColumns > 1

  const handleMouseLeave = () => {
    closeMenu(2)
  }

  const handleOnMouseEnter = () => {
    openMenu(selectedL1Item.id, 1)
  }

  const handleOnKeyDown = (event: KeyboardEvent) => {
    const activeLink = global.document && global.document.activeElement
    const activeListItem = activeLink?.parentElement
    const activeList = activeListItem?.parentElement
    const prevListItem = activeListItem?.previousElementSibling
    const nextListItem = activeListItem?.nextElementSibling

    switch (event.key) {
      case 'Tab': {
        /**
         * if user shift-tabs from the first L2 item, focus on the parent L1 item in the menubar
         */
        if (
          event.shiftKey &&
          activeLink &&
          !prevListItem &&
          activeList?.getAttribute('data-menu-level') === 'L2'
        ) {
          event.preventDefault()
          if (ref && 'current' in ref && ref.current) ref.current.focus()
          closeMenu(2)
          break
        }

        /**
         * if user shift-tabs from the first L3 item, use the default tab behaviour
         * but close the l3 menu
         */
        if (
          event.shiftKey &&
          activeLink &&
          !prevListItem &&
          activeList?.getAttribute('data-menu-level') === 'L3'
        ) {
          closeMenu(3)
          break
        }

        /**
         * behaviour for reaching the end of the L2/L3 list
         * if it exists, we want move focus to the next L1 item in the menubar
         * otherwise, we want to move focus to the current L1 item in the menubar
         */
        if (!event.shiftKey && activeLink && !nextListItem) {
          event.preventDefault()
          if (ref && 'current' in ref && ref.current) {
            const nextL1Link = ref.current.parentElement?.nextElementSibling?.firstElementChild
            nextL1Link instanceof HTMLAnchorElement ? nextL1Link?.focus() : ref.current.focus()
            closeMenu(2)
          }
        }
        break
      }

      case ' ': {
        // make spacebar act like pressing enter
        event.preventDefault()
        if (activeLink instanceof HTMLAnchorElement) activeLink?.click()
        break
      }
      case 'Escape': {
        const parentL2Id = `cat-menu-item-${selectedL2Item?.id}`
        const parentL2 = document.getElementById(parentL2Id)

        if (activeLink && activeList?.getAttribute('data-menu-level') === 'L3' && parentL2) {
          // close L3 & return focus to the parent L2 item in the menubar
          parentL2.focus()
          closeMenu(3)
        } else {
          // close L2 & return focus to the parent L1 item in the menubar
          closeMenu(2)
          if (ref && 'current' in ref && ref.current) ref.current.focus()
        }
        break
      }
    }
  }

  return (
    <>
      {/* DESKTOP VERSION */}
      <CategoryTreeWrapper
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleMouseLeave}
        onKeyDown={handleOnKeyDown}
      >
        <SubCategoryL2Group role="group">
          <SubCategoryHiddenTitle as="h3">{selectedL1Item.categoryName}</SubCategoryHiddenTitle>

          <SubCategoryMenu
            aria-labelledby={`cat-menu-item-${selectedL1Item.id}`}
            data-testid="mega-menu-l2-list"
            id={`cat-menu-group-${selectedL1Item.id}`}
            role="menu"
            data-menu-level="L2"
          >
            <SubCategories
              activeL2Items={activeL2Items}
              activeL3Items={activeL3Items}
              brandedCategoryCampaign={brandedCategoryCampaign}
              categoryLevel={2}
              isCollapsedMenuView={isCollapsedMenuView}
              isKeyboardNavigation={isKeyboardNavigation}
              openMenu={openMenu}
              parentCategoryId={selectedL1Item.id}
              parentCategoryName={selectedL1Item.categoryName}
              parentSeoUrl={selectedL1Item.seoUrl}
              selectedL2Item={selectedL2Item}
              closeMenu={closeMenu}
            />
          </SubCategoryMenu>
        </SubCategoryL2Group>

        <SubCategoryL3Container twoOrMoreColumns={twoOrMoreColumns}>
          {!!selectedL2Item && activeL3Items.length ? (
            <>
              <SubCategoryHiddenTitle as="h3">{selectedL2Item.categoryName}</SubCategoryHiddenTitle>

              <SubCategoryMenu
                aria-labelledby={`cat-menu-item-${selectedL2Item.id}`}
                data-testid="mega-menu-l3-list"
                id={`cat-menu-group-${selectedL2Item.id}`}
                isSubTree
                onMouseEnter={handleClearTimeout}
                role="menu"
                data-menu-level="L3"
              >
                <SubCategories
                  activeL2Items={activeL2Items}
                  activeL3Items={activeL3Items}
                  brandedCategoryCampaign={brandedCategoryCampaign}
                  categoryLevel={3}
                  isCollapsedMenuView={isCollapsedMenuView}
                  isKeyboardNavigation={isKeyboardNavigation}
                  openMenu={openMenu}
                  parentCategoryId={selectedL2Item.id}
                  parentCategoryName={selectedL2Item.categoryName}
                  parentSeoUrl={selectedL2Item.seoUrl}
                  closeMenu={closeMenu}
                />
              </SubCategoryMenu>
            </>
          ) : null}

          {selectedL1Item.id ? (
            <MenuImageInsetShadow>
              <Image
                alt=""
                fill
                sizes="33vw"
                src={getCategoryImage(selectedL1Item.id)}
                style={{ objectFit: 'contain', objectPosition: 'right' }}
              />
            </MenuImageInsetShadow>
          ) : null}
        </SubCategoryL3Container>
      </CategoryTreeWrapper>

      {/* MOBILE VERSION */}
      <MegaMenuModal {...props} />
    </>
  )
})
