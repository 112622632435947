import { useTranslation } from 'next-i18next'
import { useMemo } from 'react'

import { TRENDING_CATEGORIES } from '@/constants/trendingCategories'
import { ROUTE_LOCALE } from '@/domain/locale'
import {
  TrendingCategoriesBlock,
  TrendingCategoriesLink,
} from '@/features/navigation/components/styled'
import { trackMenuItemClick } from '@/features/navigation/utils/menuTracking'
import { useLocale } from '@/hooks/useLocale'

/**
 * This component is used to display the trending category in the mega menu
 *
 * @returns The trending category component
 */
export const TrendingCategory = () => {
  const { routeLocale } = useLocale()
  const { t } = useTranslation('global_header')

  const getTrendingCategory = () => {
    const today = new Date()

    /**
     * Returns the month number starting at 0 (i.e. January is month 0)
     * */
    const month = today.getMonth()

    return TRENDING_CATEGORIES[month]
  }

  const trendingCategory = useMemo(() => getTrendingCategory(), [])

  const trendingLink = useMemo(
    () => (routeLocale === ROUTE_LOCALE.fr ? trendingCategory.urlFr : trendingCategory.url),
    [routeLocale, trendingCategory.url, trendingCategory.urlFr]
  )

  const handleTrackingOnClick = () => {
    trackMenuItemClick(trendingCategory.categoryId, 'menu_trending')
  }

  return (
    <TrendingCategoriesLink
      data-testid="menu-trending-category"
      href={trendingLink}
      noStyle
      onClick={handleTrackingOnClick}
    >
      <TrendingCategoriesBlock>{t('mega_menu.trending_category.title')}</TrendingCategoriesBlock>
      {t(`mega_menu.trending_category.categories.${trendingCategory.categoryId}`)}
    </TrendingCategoriesLink>
  )
}
