import {
  type AppliedFilter,
  type AreaLocationInput,
  type AttributeFilter,
  type AttributeFilterInput,
  type BoundingBox,
  type DateFilter,
  type DateFilterInput,
  type DateRangeFilterInput,
  type Filter,
  type RangeFilterInput,
  type SearchUrlInput,
  type SrpSortInput,
  type ToggleFilter,
  type ToggleFilterInput,
  type UserLocation,
  AdditionalFlags,
  FilterControlType,
  GetPendingSearchInputDocument,
  useGetPendingSearchInputQuery,
  useGetSeoUrlLazyQuery,
} from '@kijiji/generated/graphql-types'
import debounce from 'lodash/debounce'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useRef } from 'react'

import { ALL_CATEGORIES_ID_NUM } from '@/constants/category'
import { SRP_LISTING_LIMIT } from '@/constants/pageSettings'
import {
  type ParentFilter,
  getParentFilter as getParentFilterHelper,
} from '@/domain/srp/filters/getParentFilter'
import {
  mergeRefetchInputs,
  prepareRefetchInputForRequest,
  transformSearchQueryToRefetchInput,
} from '@/domain/srp/filters/handleRefetchInputs'
import { sortAndShortenSrpUrl } from '@/domain/srp/getSrpUrlCacheKey'
import { mountDominantCategorySeoUrl, mountTopAdsSeoUrl } from '@/domain/urls'
import { useGetSearchResultsData } from '@/hooks/srp/useGetSearchResultsData'
import { useSearchLoadingState } from '@/hooks/srp/useSearchLoadingState'
import { type TrackEventArgs, trackEvent } from '@/lib/ga'
import { type DateRangeFilter, type RangeFilterMinMax, isToggleFilter } from '@/types/search'
import { replaceUndefinedWithNull } from '@/utils/object'
import { sendToLogger } from '@/utils/sendToLogger'

export enum FilterKeysEnum {
  ATTRIBUTE_FILTERS = 'attributeFilters',
  RANGE_FILTERS = 'rangeFilters',
  DATE_RANGE_FILTERS = 'dateRangeFilters',
  DATE_FILTERS = 'dateFilters',
  TOGGLE_FILTERS = 'toggleFilters',
  ADDITIONAL_FLAG_FILTERS = 'additionalFlagFilters',
}

export type SearchQueryInput = {
  keywords?: string
  categoryId: number
  location: { id: number; area?: AreaLocationInput | null; boundingBox?: BoundingBox }
  topAdCount?: number
  [FilterKeysEnum.ADDITIONAL_FLAG_FILTERS]?: AdditionalFlags[]
  [FilterKeysEnum.ATTRIBUTE_FILTERS]?: AttributeFilterInput[]
  [FilterKeysEnum.DATE_FILTERS]?: DateFilterInput[]
  [FilterKeysEnum.DATE_RANGE_FILTERS]?: DateRangeFilterInput[]
  [FilterKeysEnum.RANGE_FILTERS]?: RangeFilterInput[]
  [FilterKeysEnum.TOGGLE_FILTERS]?: ToggleFilterInput[]
}

const getFilterKeyEnumFromType = (filter: Filter) => {
  switch (filter.type) {
    case FilterControlType.Checkboxes:
    case FilterControlType.MultiSelect:
    case FilterControlType.Radio:
      return FilterKeysEnum.ATTRIBUTE_FILTERS
    case FilterControlType.Toggles:
      return isToggleFilter(filter)
        ? FilterKeysEnum.TOGGLE_FILTERS
        : FilterKeysEnum.ATTRIBUTE_FILTERS
    case FilterControlType.HasFilters:
      return FilterKeysEnum.ADDITIONAL_FLAG_FILTERS
    case FilterControlType.Date:
      return FilterKeysEnum.DATE_FILTERS
    case FilterControlType.DateRange:
      return FilterKeysEnum.DATE_RANGE_FILTERS
    case FilterControlType.Range:
      return FilterKeysEnum.RANGE_FILTERS
    default:
      return undefined
  }
}

// Is essentially the same as SearchUrlInput but flattened
export type RefetchInput = SearchQueryInput & {
  offset?: number
  by?: SrpSortInput['by']
  direction?: SrpSortInput['direction']
}

export type RefetchResultsType = {
  (
    refetchInput: Partial<RefetchInput>,
    options: {
      event?: TrackEventArgs
      debounce: true
      forceNavigateUrl?: null
    }
  ): Promise<void>
  (
    refetchInput: Partial<RefetchInput>,
    options?: {
      event?: TrackEventArgs
      debounce?: false
      forceNavigateUrl?: string
    }
  ): Promise<void>
}

type GetFilterWithSelectedValues = {
  (filter: DateFilter): DateFilter
  (filter: AttributeFilter): AttributeFilter
  (filter: DateRangeFilter): DateRangeFilter
  (filter: RangeFilterMinMax): RangeFilterMinMax
  (filter: ToggleFilter): ToggleFilter
}

type GetParentFilter = (filter: Filter) => ParentFilter | undefined

export type SearchActionsResult = {
  refetchResults: RefetchResultsType
  getFilterWithSelectedValues: GetFilterWithSelectedValues
  getParentFilter: GetParentFilter
  handleNewSearch: (selectedCategoryId: number, newKeyword: string, location: UserLocation) => void
}

export const RESET_FILTERS_INPUT: Partial<RefetchInput> = {
  additionalFlagFilters: [],
  attributeFilters: [],
  dateFilters: [],
  dateRangeFilters: [],
  keywords: '',
  offset: 0,
  rangeFilters: [],
  toggleFilters: [],
  topAdCount: 5,
}

/**
 * Custom hook that provides search actions for the search results page.
 * It includes functions for refetching search results and retrieving filter information.
 *
 * @returns An object containing the search actions.
 */
export const useSearchActions = ({ newSearch = false } = {}): SearchActionsResult => {
  const { data, client } = useGetSearchResultsData({
    fetchPolicy: 'cache-only',
    skip: !!newSearch,
  })
  const currentDataRef = useRef(data)

  const fullFilterSet = data?.controls.filtering

  const pendingSearchInput =
    useGetPendingSearchInputQuery({ skip: !!newSearch }).data?.srp.pendingSearchInput || {}

  const { setLoadingStates } = useSearchLoadingState()
  const { push } = useRouter()

  const [fetchSeoUrl] = useGetSeoUrlLazyQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  })

  useEffect(() => {
    currentDataRef.current = data
  }, [data])

  const debouncedRefetch = useCallback(
    debounce(async (refetchInput: Partial<RefetchInput>) => {
      const {
        pagination: currentPagination,
        searchQuery: currentSearchQuery,
        controls,
      } = currentDataRef.current || {}

      const selectedSort = controls?.sorting.find((sort) => sort.isSelected)
      const currentSort = selectedSort
        ? {
            by: selectedSort.by,
            direction: selectedSort.direction,
          }
        : undefined

      const mergedInput = prepareRefetchInputForRequest(
        refetchInput,
        transformSearchQueryToRefetchInput(currentSearchQuery, currentPagination, currentSort)
      )

      const { by, direction, offset, categoryId, ...restMergedInput } = mergedInput
      const isDominantCategorySearch = !refetchInput.categoryId && !!refetchInput.keywords

      const input: SearchUrlInput = {
        searchQuery: {
          ...restMergedInput,
          categoryId: isDominantCategorySearch ? undefined : categoryId,
        },
        ...(by && direction
          ? {
              sorting: {
                by,
                direction,
              },
            }
          : {}),
        // any refetch should reset to page 1
        pagination: { offset: 0, limit: SRP_LISTING_LIMIT },
      }

      const seoUrl = (await fetchSeoUrl({ variables: { input } })).data?.searchUrl

      if (!seoUrl) {
        sendToLogger('useGetSeoUrlLazyQuery returned with no data')
        return
      }

      /**
       * Cleanup URL before fetching results
       *
       * Avoid issues for the user with seoURL in case it's returned as a string instead of URL
       * */
      let redirectUrl: string = seoUrl

      // If new keywords and new category is 0 or undefined, it's a dominant category search
      if (isDominantCategorySearch) {
        /** Append ?dc=true query parameter*/
        redirectUrl = mountDominantCategorySeoUrl(redirectUrl, ALL_CATEGORIES_ID_NUM)
      }

      /**
       * Is a TopAd only search
       */
      const isTopAdsSearch = !!mergedInput.additionalFlagFilters?.includes(AdditionalFlags.TopAd)
      if (isTopAdsSearch) {
        /** Append  ?gpTopAds='y' query parameter*/
        redirectUrl = mountTopAdsSeoUrl(redirectUrl)
      }

      /* Only push if we have generated a new url */
      if (sortAndShortenSrpUrl(window.location.toString()) === sortAndShortenSrpUrl(redirectUrl)) {
        setLoadingStates(false)
      } else {
        push(redirectUrl, undefined, { scroll: newSearch, shallow: !newSearch })
      }
    }, 800),
    []
  )

  useEffect(() => {
    return () => {
      debouncedRefetch.cancel()
    }
  }, [debouncedRefetch])

  const refetchResults: RefetchResultsType = async (
    refetchInput,
    { event, debounce, forceNavigateUrl } = {}
  ) => {
    const combinedPendingSearchInput = mergeRefetchInputs(
      pendingSearchInput as Partial<RefetchInput>, // TODO: Get rid of this cast?
      refetchInput
    )

    setLoadingStates({
      results: true,
      filters: !!(combinedPendingSearchInput.categoryId ?? combinedPendingSearchInput.location),
    })

    debouncedRefetch(combinedPendingSearchInput)

    if (event) {
      trackEvent({ ...event })
    }

    if (debounce) {
      client.writeQuery({
        query: GetPendingSearchInputDocument,
        data: {
          srp: { pendingSearchInput: replaceUndefinedWithNull(combinedPendingSearchInput) },
        }, // save tracking label per each thing in here
        broadcast: true,
      })
    } else if (forceNavigateUrl && !pendingSearchInput) {
      debouncedRefetch.cancel()
      push(forceNavigateUrl, undefined, { scroll: newSearch, shallow: !newSearch })
    } else {
      debouncedRefetch.flush()
    }
  }

  const getFilterWithSelectedValues: GetFilterWithSelectedValues = ((filter) => {
    const key = getFilterKeyEnumFromType(filter)

    const findFilter = (f: AppliedFilter) => f.filterName === filter.name

    switch (key) {
      case FilterKeysEnum.ATTRIBUTE_FILTERS: {
        const pendingValues =
          pendingSearchInput?.[FilterKeysEnum.ATTRIBUTE_FILTERS]?.find(findFilter)?.values
        const hasPendingInput = pendingValues !== undefined

        return hasPendingInput ? { ...filter, selectedValues: pendingValues } : filter
      }
      case FilterKeysEnum.DATE_FILTERS: {
        const pendingValue =
          pendingSearchInput?.[FilterKeysEnum.DATE_FILTERS]?.find(findFilter)?.value
        const hasPendingInput = pendingValue !== undefined

        return hasPendingInput ? { ...filter, selectedValue: pendingValue } : filter
      }
      case FilterKeysEnum.DATE_RANGE_FILTERS: {
        const { start, end } =
          pendingSearchInput?.[FilterKeysEnum.DATE_RANGE_FILTERS]?.find(findFilter) || {}
        const hasPendingInput = start !== undefined || end !== undefined

        return hasPendingInput ? { ...filter, start, end } : filter
      }
      case FilterKeysEnum.RANGE_FILTERS: {
        const { minValue, maxValue } =
          pendingSearchInput?.[FilterKeysEnum.RANGE_FILTERS]?.find(findFilter) || {}
        const hasPendingInput = minValue !== undefined || maxValue !== undefined

        return hasPendingInput ? { ...filter, minValue, maxValue } : filter
      }
      case FilterKeysEnum.TOGGLE_FILTERS: {
        const toggleValue =
          pendingSearchInput?.[FilterKeysEnum.TOGGLE_FILTERS]?.find(findFilter)?.toggleValue
        const hasPendingInput = toggleValue !== undefined
        const trueValue = (filter as ToggleFilter).trueValue

        return hasPendingInput ? { ...filter, isSelected: trueValue === toggleValue } : filter
      }
      case FilterKeysEnum.ADDITIONAL_FLAG_FILTERS: {
        const pendingValues = pendingSearchInput?.[FilterKeysEnum.ADDITIONAL_FLAG_FILTERS]

        return pendingValues ? { ...filter, selectedValues: pendingValues } : filter
      }
      default:
        return filter
    }
  }) as GetFilterWithSelectedValues

  const getParentFilter: GetParentFilter = (filter) => {
    if (!filter.parentName) {
      return undefined
    }

    return (
      (filter.parentName && fullFilterSet && getParentFilterHelper(filter, fullFilterSet)) ||
      undefined
    )
  }

  const handleNewSearch = async (
    selectedCategoryId: number,
    newKeyword: string,
    location: UserLocation
  ) => {
    // Strip single quotes from user inputted keywords
    // TODO: Investigate why NWA is no longer handling single quotes
    const sanitizedKeyWord = newKeyword?.replace(/'/g, '')

    refetchResults({
      keywords: sanitizedKeyWord,
      categoryId: selectedCategoryId,
      location: { id: location.id, area: location.area },
    })
  }

  return {
    refetchResults,
    getParentFilter,
    getFilterWithSelectedValues,
    handleNewSearch,
  }
}
