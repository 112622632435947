import { type FC, useRef } from 'react'

import { CategoryTree } from '@/features/navigation/components/mega-menu/desktop/CategoryTree'
import { L1Menu } from '@/features/navigation/components/mega-menu/desktop/L1Menu'
import { useActiveMegaMenu } from '@/features/navigation/hooks/useActiveMegaMenu'

export type MegaMenuProps = {
  locationId: number
  icons?: boolean
}

export const MegaMenu: FC<MegaMenuProps> = ({ locationId, icons = true }) => {
  const {
    activeL2Items,
    activeL3Items,
    brandedCategoryCampaign,
    closeMenu,
    handleClearTimeout,
    isCollapsedMenuView,
    isKeyboardNavigation,
    l1MenuItems,
    menuBarRef,
    onHover,
    openMenu,
    selectedL1Item,
    selectedL2Item,
  } = useActiveMegaMenu(locationId)

  const selectedL1Ref = useRef<HTMLAnchorElement>(null)

  return (
    <L1Menu
      closeMenu={closeMenu}
      handleClearTimeout={handleClearTimeout}
      isCollapsedMenuView={isCollapsedMenuView}
      l1MenuItems={l1MenuItems}
      menuBarRef={menuBarRef}
      ref={selectedL1Ref}
      onHover={onHover}
      openMenu={openMenu}
      selectedL1Item={selectedL1Item}
      icons={icons}
    >
      {!!selectedL1Item?.id && (
        <CategoryTree
          activeL2Items={activeL2Items}
          activeL3Items={activeL3Items}
          brandedCategoryCampaign={brandedCategoryCampaign}
          closeMenu={closeMenu}
          handleClearTimeout={handleClearTimeout}
          isCollapsedMenuView={isCollapsedMenuView}
          openMenu={openMenu}
          selectedL1Item={selectedL1Item}
          selectedL2Item={selectedL2Item}
          ref={selectedL1Ref}
          isKeyboardNavigation={isKeyboardNavigation}
        />
      )}
    </L1Menu>
  )
}
